<template>
  <div class="question-container">
    <div class="question-container__header">
      <div class="header-left">
        <DragDots class="drag" />
        <div class="question-number">{{ $t('label.question') }} № {{ index + 1 }}</div>
      </div>
      <div class="header-right">
        <div class="multi-checkbox">
          <b-form-checkbox :id="item._id" v-model="is_multiple" name="checkbox-1">
            {{ $t('label.multipleAnswers') }}
          </b-form-checkbox>
        </div>
        <SmallCloseButton @click="$emit('remove', item._id)" />
      </div>
    </div>
    <div class="question-container__body">
      <div class="question-title-input">
        <AppInput
          :value="item.name"
          ref="question"
          name="question"
          :label="$t('placeholders.writeYourQuestionHere')"
          @blur="setField"
        />
      </div>
      <div class="answers-container">
        <ContentList
          :disableDragAndDrop="isDisabledDragAndDrop"
          :groupName="'answerList' + item._id"
          :list="answers"
          :question="item"
          handle=".answer-tile__dragdots"
          type="answer"
          @changeOrder="changeOrder"
        />
      </div>
      <div class="question-actions">
        <b-button
          class="answer-btn"
          type="submit"
          variant="primary"
          :disabled="loadingCreateAnswer"
          @click="addAnswer"
        >
          <div class="label" v-if="!loadingCreateAnswer">
            <PlusIconBlack />
            {{ $t('placeholders.answer') }}
          </div>
          <div class="label" v-else>
            <b-spinner small class="loader"></b-spinner>
            {{ $t('label.processing') }}
          </div>
        </b-button>
        <div class="question-actions__score">
          <div class="score-container">
            <span class="score-title">{{ $t('label.scorePerCorrectAnswer') }}</span>
            <LmsInput
              class="mt-2"
              id="material-score"
              type="number"
              placeholder="max 999"
              :value="item.max_score"
              @change="setScore"
              :class="{ invalid: $v.max_score.$dirty && !$v.max_score.maximumNotExceeded }"
            />
            <div
              class="error-message"
              :class="{
                'error-message--activate': $v.max_score.$dirty && !$v.max_score.maximumNotExceeded,
              }"
            >
              <p>{{ $t('label.maximum') }} 999</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SmallCloseButton from '@/components/Buttons/SmallCloseButton.vue';
import DragDots from '@/components/Icons/DragDots.vue';
import AppInput from '@/components/AppInput.vue';
import LmsInput from '@/components/LmsInput.vue';
import PlusIconBlack from '@/components/Icons/PlusIconBlack.vue';

export default {
  name: 'QuestionCard',
  components: {
    PlusIconBlack,
    SmallCloseButton,
    DragDots,
    AppInput,
    LmsInput,
    ContentList: () => import('@/components/ContentList.vue'),
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  validations: {
    max_score: {
      maximumNotExceeded(int) {
        return int < 1000;
      },
    },
  },
  provide() {
    const question = {};
    Object.defineProperty(question, 'meta', {
      enumerable: true,
      get: () => this.item.meta,
    });

    return {
      updateAnswers: this.updateAnswers,
      question,
    };
  },
  data: () => ({
    multi: false,
    questionText: '',
    isDisabledDragAndDrop: false,
    max_score: null,
    loadingCreateAnswer: false,
  }),
  computed: {
    ...mapGetters('quizTeacher', ['loading']),
    name: {
      get() {
        return this.item?.name ?? '';
      },
      set() {},
    },
    answers: {
      get() {
        return this.item.meta.answers;
      },
      set(answers) {
        const clone = JSON.parse(JSON.stringify(this.item));
        clone.meta = { is_multiple: this.item.meta.is_multiple, answers };
        this.updateQuestion({
          id: this.item._id,
          question: clone,
        });
      },
    },
    is_multiple: {
      get() {
        return this.item.meta.is_multiple;
      },
      set(bool) {
        const meta = { ...this.item.meta, is_multiple: bool };
        const numberOfSelectedAnswers = meta.answers.filter((a) => a.is_correct).length;
        if (!bool) {
          if (numberOfSelectedAnswers > 1) {
            let firstAnswer = false;
            meta.answers = meta.answers.map((a) => {
              if (a.is_correct && !firstAnswer) {
                firstAnswer = true;
              } else if (a.is_correct) {
                const clone = JSON.parse(JSON.stringify(a));
                // eslint-disable-next-line no-param-reassign
                clone.is_correct = false;
                return clone;
              }
              return a;
            });
          }
          if (numberOfSelectedAnswers === 0 && meta.answers.length) {
            const cloneAnswers = JSON.parse(JSON.stringify(meta.answers));
            cloneAnswers[0].is_correct = true;
            meta.answers = cloneAnswers;
          }
        }
        if (bool) {
          if (numberOfSelectedAnswers === 0 && meta.answers.length) {
            const cloneAnswers = JSON.parse(JSON.stringify(meta.answers));
            cloneAnswers[0].is_correct = true;
            meta.answers = cloneAnswers;
          }
        }
        this.updateQuestion({
          id: this.item._id,
          question: { ...this.item, meta },
        });
      },
    },
  },
  methods: {
    ...mapActions({ setToaster: 'toaster/setToaster' }),
    ...mapActions('quizTeacher', ['createAnswer', 'updateQuestion']),
    ...mapActions('requestQueue', ['addToQueue', 'activateQueue']),
    /**
     * Update answer based on current selector this.is_multiple - multiple answers / single
     * @param {string} answerId
     */
    updateAnswers(answerId) {
      const clone = JSON.parse(JSON.stringify(this.item.meta.answers));
      this.answers = clone.map((a) => {
        // Handle answer in case of multiple answer question
        if (this.is_multiple) {
          if (a.id === answerId) {
            // eslint-disable-next-line no-param-reassign
            a.is_correct = !a.is_correct;
            return a;
          }
          return a;
        }

        // Handle answer in case of single answer question
        // eslint-disable-next-line no-unused-expressions,no-param-reassign
        a.id === answerId ? (a.is_correct = true) : (a.is_correct = false);
        return a;
      });
    },
    setField(e) {
      const newName = e.target.value;
      if (this.item.name !== newName) {
        this.updateQuestion({ id: this.item._id, question: { name: newName } });
      }
    },
    setScore(e) {
      const newScore = parseInt(e, 10);
      this.max_score = newScore;
      this.$v.max_score.$touch();
      if (Number.isNaN(this.max_score)) {
        this.$v.max_score.$reset();
        return;
      }
      if (this.$v.max_score.$anyError) {
        return;
      }
      if (this.item.max_score !== newScore) {
        this.updateQuestion({
          id: this.item._id,
          question: {
            max_score: newScore || 0,
          },
        });
      }
    },
    changeOrder(newAnswers) {
      this.answers = newAnswers;
    },
    addAnswer() {
      this.$nextTick(() => {
        this.loadingCreateAnswer = true;
        if (this.loading) {
          this.addToQueue(this._addAnswer);
        } else {
          this._addAnswer();
        }
      });
    },
    _addAnswer() {
      this.createAnswer(this.item)
        .catch(this.serverErrorToast)
        .finally(() => (this.loadingCreateAnswer = false));
    },
    serverErrorToast() {
      const toast = {
        title: this.$t('errorMessages.somethingWentWrong'),
        body: this.$t('errorMessages.tryAgain'),
      };
      this.setToaster({
        type: 'toast-danger',
        toast,
      });
    },
  },
  watch: {
    loading: {
      handler(bool) {
        if (!bool) {
          this.activateQueue();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input.form-control:focus {
  color: #f2f2f2;
  box-shadow: none;
}

::v-deep .list-group-item:not(:last-child) {
  padding-bottom: 0.625rem;
}
</style>
