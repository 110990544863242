<template>
  <div class="material-user-tile" :class="{ disabled: false }" @click="openViewer">
    <div class="material-user-tile__left">
      <div class="material-user-tile__icon">
        <component :is="iconComponent" />
      </div>
      <div class="material-user-tile__title">
        {{ index + 1 }}.&nbsp;{{ item.name }}
      </div>
    </div>
    <div class="material-user-tile__right">
      <div class="score" v-if="item.max_score !== 0">
        <!-- eslint-disable max-len -->
        {{`${item.statistic.score} / ${item.max_score} ${$tc('label.pointsPluralRod', item.max_score)}`}}
      </div>
      <div class="score" v-else>
        {{$t('label.noScore')}}
      </div>
      <ProgressStatus class="status" iconPosition="right" :status="status" />
    </div>
  </div>
</template>

<script>
import constantsMixin from '@/mixins/constants.mixin';
import ProgressStatus from '@/components/ProgressStatus.vue';
import PptxIcon from '@/components/Icons/PptxIcon.vue';
import PdfIcon from '@/components/Icons/PdfIcon.vue';
import DocxIcon from '@/components/Icons/DocxIcon.vue';
import {
  doing, done, notStarted, rejected,
} from '@/utils/constants';
import QuizIcon from '@/components/Icons/QuizIcon.vue';
import VideoIcon from '@/components/Icons/VideoIcon.vue';
import { ActivityStatuses } from '@/utils/statusConstants';

export default {
  name: 'MaterialUserTile',
  components: {
    DocxIcon,
    PdfIcon,
    PptxIcon,
    ProgressStatus,
  },
  props: {
    item: {
      type: [Object, String],
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  mixins: [constantsMixin],
  computed: {
    iconComponent() {
      if (this.item.type === 'quiz') return QuizIcon;
      if (this.item.type === 'video') return VideoIcon;
      if (this.item.type === 'task') return DocxIcon;
      if (this.item.meta?.fileExtension === this.pptx) return PptxIcon;
      if (this.item.meta?.fileExtension === this.pdf) return PdfIcon;
      if (this.item.meta?.fileExtension === this.docx) return DocxIcon;
      return DocxIcon;
    },
    status() {
      if (this.item.statistic.status === ActivityStatuses.done && this.item.type === 'task') {
        return {
          value: 'done',
          label: 'label.accepted',
        };
      }
      if (this.item.statistic.status === ActivityStatuses.done) {
        return done;
      }
      if (this.item.statistic.status === ActivityStatuses.doing) {
        return doing;
      }
      if (this.item.statistic.status === ActivityStatuses.rejected && this.item.type === 'quiz') {
        return {
          value: 'rejected',
          label: 'label.rejectedMasculine',
        };
      }
      if (this.item.statistic.status === ActivityStatuses.rejected) {
        return rejected;
      }
      if (this.item.type === 'task') {
        return {
          value: 'not-started',
          label: 'label.notStartedTask',
        };
      }
      return notStarted;
    },
  },
  methods: {
    openViewer() {
      const { programId, courseId } = this.$route.params;
      this.$router.push(`/viewer/${programId}/${courseId}?activityId=${this.item._id}`);
    },
  },
};
</script>
